<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_REALTY">
    <v-section :title="isNmh ? 'Название и описание для сайта' : 'Описание'" class="realty-edit-description">
      <v-form-row class="realty-edit-about-object__row">
        <div class="realty-edit-about-object__realty-name">
          <v-form-field
            v-slot="{ validationErrors }"
            :label="isNmh ? 'НАЗВАНИЕ ДЛЯ САЙТА' : 'НАЗВАНИЕ'"
            class="realty-edit-about-object__field"
            rules="required"
          >
            <v-input v-model="model.name" :is-error="!!validationErrors.length" />
          </v-form-field>
        </div>
      </v-form-row>
      <v-form-row>
        <v-form-field v-slot="{ validationErrors }" label="ДОБАВЬТЕ ОПИСАНИЕ СОГЛАСНО РЕГЛАМЕНТУ" rules="required">
          <v-textarea v-model="model.description" :is-error="!!validationErrors.length" />
        </v-form-field>
      </v-form-row>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VTextarea from '@/components/common/VTextarea.vue'
import VInput from '@/components/common/VInput.vue'
import { mapGetters, mapState } from 'vuex'
import { DESCRIPTION_INFO, MODULE_REALTY } from '@/store/modules/realty/realty.types'

import { cloneDeep } from '@/utils/common'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

const SECTION_TYPE = DESCRIPTION_INFO
export default {
  SECTION_TYPE,
  MODULE_REALTY,
  name: 'ObjectsRealtyEditDescription',
  components: { VInput, FieldsetWrapper, VSection, VFormField, VFormRow, VTextarea },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    ...mapState(MODULE_REALTY, {
      stateModel: state => state[SECTION_TYPE]
    }),
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    })
  },
  created() {
    this.model = cloneDeep(this.stateModel)
  }
}
</script>
