<template>
  <match-media v-slot="{ mobile }" class="realty-edit-select-options">
    <div v-if="!mobile" class="realty-edit-select-options__field realty-edit-select-options__field--radio">
      <template v-if="!loading">
        <v-radio
          v-for="(option, index) in options"
          :key="index"
          v-model="localValue"
          :value="option.value"
          class="realty-edit-select-options__radio"
          >{{ option.label }}</v-radio
        >
      </template>
      <template v-else>
        Загрузка данных...
      </template>
    </div>

    <div v-else class="realty-edit-select-options__select">
      <v-select
        v-model="localValue"
        :loading="loading"
        :reduce="option => option.value"
        :options="options"
        :is-error="isError"
      />
    </div>
  </match-media>
</template>

<script>
import VRadio from '@/components/common/VRadio.vue'
import VSelect from '@/components/common/VSelect.vue'
import { MatchMedia } from 'vue-component-media-queries'

export default {
  name: 'ObjectsRealtyEditSelectOptions',
  components: { VRadio, VSelect, MatchMedia },
  props: {
    value: {
      type: [String, Number, Object, Boolean],
      default: ''
    },
    isError: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss"></style>
