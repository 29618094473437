<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_REALTY">
    <v-section title="Статус и выгрузка" class="realty-edit-basic">
      <v-form-row class="realty-edit-basic__row">
        <v-form-field label="ОТОБРАЖЕНИЕ" separate-label>
          <div class="realty-edit-basic__field realty-edit-basic__field--checkboxes">
            <v-checkbox v-model="model.isPublished" class="realty-edit-basic__checkbox">Опубликован</v-checkbox>
            <v-checkbox v-if="isNmh" v-model="model.isSpecial" class="realty-edit-basic__checkbox"
              >Главная страница</v-checkbox
            >
          </div>
        </v-form-field>
      </v-form-row>

      <v-form-row v-if="isRoleAdmin" class="realty-edit-basic__row">
        <v-form-field label="ВЫГРУЗКИ (XML)" separate-label>
          <div class="realty-edit-basic__field realty-edit-basic__field--checkboxes">
            <v-checkbox v-model="model.xmlYandex" class="realty-edit-basic__checkbox">Яндекс.Недвижимость</v-checkbox>
            <v-checkbox v-if="isNmh" v-model="model.xmlFacebook" class="realty-edit-basic__checkbox"
              >Facebook</v-checkbox
            >
            <v-checkbox v-model="model.xmlCian" class="realty-edit-basic__checkbox">Циан</v-checkbox>
            <v-checkbox v-model="model.xmlAvito" class="realty-edit-basic__checkbox">Авито</v-checkbox>
            <v-checkbox v-model="model.xmlYandexDirect" class="realty-edit-basic__checkbox">Яндекс.Директ</v-checkbox>
            <v-checkbox v-model="model.xmlDomClick" class="realty-edit-basic__checkbox">ДомКлик</v-checkbox>
          </div>
        </v-form-field>
      </v-form-row>

      <v-form-row v-if="isRoleAdmin" class="realty-edit-basic__row">
        <v-form-field label="ТИП РАЗМЕЩЕНИЯ ЦИАН" separate-label>
          <select-options v-model="model.publishTerms" :options="$options.CIAN_PLACEMENTS_TYPE_OPTIONS" />
        </v-form-field>
      </v-form-row>

      <v-form-row v-if="isRoleAdmin" class="realty-edit-basic__row realty-edit-basic__row--cian">
        <v-form-field label="СТАВКА АУКЦИОНА ЦИАН" class="realty-edit-basic__field--cian-auction">
          <v-input v-model="model.cianAuctionBid" type="number" />
        </v-form-field>

        <v-form-field
          v-if="!isNmh"
          label="ЗАГОЛОВОК ДЛЯ ТОП ОБЪЯВЛЕНИЯ ЦИАН"
          class="realty-edit-basic__field--cian-title"
        >
          <v-input v-model="model.cianTitle" type="text" />
        </v-form-field>
      </v-form-row>

      <v-form-row class="realty-edit-basic__row">
        <v-form-field v-slot="{ validationErrors }" label="ВНУТРЕННИЙ СТАТУС" separate-label rules="required">
          <select-options
            v-model="model.status"
            :options="$options.STATUS_OPTIONS"
            :is-error="!!validationErrors.length"
          />
        </v-form-field>
      </v-form-row>

      <v-form-row
        v-if="model.status === $options.STATUS_SOLD"
        class="realty-edit-basic__row realty-edit-basic__row--sold-info"
      >
        <v-form-field label="КЕМ ПРОДАН" class="realty-edit-basic__field--sale-source">
          <v-select
            v-model="model.saleSource"
            :clearable="false"
            :options="saleSources"
            label="label"
            :reduce="option => option.value"
          ></v-select>
        </v-form-field>
        <v-form-field label="ДАТА ПРОДАЖИ" class="realty-edit-basic__field--soldout-date">
          <v-input-date v-model="model.soldoutAt" format="DD.MM.YYYY"></v-input-date>
        </v-form-field>
      </v-form-row>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VInputDate from '@/components/common/VInputDate.vue'
import VSelect from '@/components/common/VSelect.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VCheckbox from '@/components/common/VCheckbox.vue'
import VInput from '@/components/common/VInput.vue'
import selectOptions from '@/components/ObjectsRealty/Edit/SelectOptions.vue'
import { CIAN_PLACEMENTS_TYPE_OPTIONS, STATUS_OPTIONS, STATUS_SOLD } from '@/constants/objectRealtyOptions'
import { mapActions, mapGetters, mapState } from 'vuex'
import { FETCH_TENANT_INFO, MODULE_SESSION } from '@/store/modules/session/session.types'
import { BASIC_INFO, MODULE_REALTY, SALE_SOURCES } from '@/store/modules/realty/realty.types'
import { cloneDeep } from '@/utils/common'

const SECTION_TYPE = BASIC_INFO

export default {
  CIAN_PLACEMENTS_TYPE_OPTIONS,
  STATUS_OPTIONS,
  MODULE_REALTY,
  SECTION_TYPE,
  STATUS_SOLD,

  name: 'ObjectsRealtyEditBasic',
  components: {
    VSelect,
    VInputDate,
    FieldsetWrapper,
    VSection,
    VFormField,
    VFormRow,
    VCheckbox,
    VInput,
    selectOptions
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`,
      isNmh: `${MODULE_SESSION}/isNmh`,
      tenantOrganizationName: `${MODULE_SESSION}/tenantOrganizationName`,
      saleSources: `${MODULE_REALTY}/${SALE_SOURCES}`
    }),
    ...mapState(MODULE_REALTY, {
      stateModel: state => state[SECTION_TYPE]
    }),
    advertStatus() {
      return this.stateModel.status
    }
  },
  watch: {
    advertStatus: {
      handler() {
        this.resetInfoAboutSale()
      }
    }
  },
  created() {
    this.model = cloneDeep(this.stateModel)
    if (!this.tenantOrganizationName) {
      this.fetchTenantInfo()
    }
  },
  methods: {
    ...mapActions(MODULE_SESSION, {
      fetchTenantInfo: FETCH_TENANT_INFO
    }),
    resetInfoAboutSale() {
      if (this.model.status !== STATUS_SOLD) {
        this.model.soldoutAt = null
        this.model.saleSource = ''
      }
    }
  }
}
</script>
