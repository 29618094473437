<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_REALTY">
    <v-section title="Добавьте ссылки из сервисов" class="realty-edit-links">
      <v-form-row class="realty-edit-links__row">
        <v-form-field
          label="ССЫЛКА ЦИАН"
          separate-label
          class="realty-edit-links__field"
          vid="cian"
          rules="requiredIfValuesEmpty:@yandex,@domClick"
        >
          <v-input v-model="model.cianUrl" />
        </v-form-field>
      </v-form-row>
      <v-form-row class="realty-edit-links__row">
        <v-form-field
          label="ССЫЛКА ЯНДЕКСА"
          separate-label
          class="realty-edit-links__field"
          vid="yandex"
          rules="requiredIfValuesEmpty:@cian,@domClick"
        >
          <v-input v-model="model.yandexUrl" />
        </v-form-field>
      </v-form-row>
      <v-form-row class="realty-edit-links__row">
        <v-form-field
          label="ССЫЛКА ДомКлик"
          separate-label
          class="realty-edit-links__field"
          vid="domClick"
          rules="requiredIfValuesEmpty:@yandex,@cian"
        >
          <v-input v-model="model.domClickUrl" />
        </v-form-field>
      </v-form-row>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VInput from '@/components/common/VInput.vue'
import { MODULE_REALTY, LINKS } from '@/store/modules/realty/realty.types'
import { mapState } from 'vuex'
import cloneDeep from 'lodash.clonedeep'

const SECTION_TYPE = LINKS

export default {
  SECTION_TYPE,
  MODULE_REALTY,
  name: 'ObjectsRealtyEditLinks',
  components: {
    FieldsetWrapper,
    VSection,
    VFormField,
    VFormRow,
    VInput
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    ...mapState(MODULE_REALTY, {
      stateModel: state => state[SECTION_TYPE]
    })
  },
  created() {
    this.model = cloneDeep(this.stateModel)
  }
}
</script>
