<template>
  <match-media v-slot="{ mobile }">
    <validation-observer ref="commonObserver" tag="div" class="realty-edit">
      <div class="realty-edit__header">
        <v-breadcrumbs v-if="!mobile" :items="breadcrumbItems" class="realty-edit__breadcrumbs" />

        <v-button-go-back v-else @click="goBack" />

        <v-page-title class="realty-edit__title">{{ title }}</v-page-title>

        <div v-if="!mobile" class="realty-edit__tabs-wrapper">
          <v-tabs v-model="activeSection" :primary="mobile">
            <v-tab name="general">
              <span v-if="isGeneralTabFilled" class="realty-edit__tab-icon"><v-icon-tick /> </span>
              Общее
            </v-tab>
            <v-tab name="placement">
              <span v-if="validations.placementSectionValid" class="realty-edit__tab-icon"><v-icon-tick /> </span>
              Расположение
            </v-tab>
            <v-tab name="photos">
              <span v-if="validations.photosSectionValid" class="realty-edit__tab-icon"><v-icon-tick /> </span>
              Фотографии
            </v-tab>
            <v-tab v-if="isRoleAdmin" name="agent">
              <span v-if="validations.agentsSectionValid" class="realty-edit__tab-icon"><v-icon-tick /> </span>
              Ответственный агент
            </v-tab>
            <v-tab v-if="isRoleAdmin" name="links">
              <span v-if="validations.linksSectionValid" class="realty-edit__tab-icon"><v-icon-tick /> </span>
              Ссылки
            </v-tab>
          </v-tabs>
        </div>
      </div>

      <div class="realty-edit__content">
        <div v-if="mobile" class="realty-edit__mobile-menu">
          <v-menu v-if="!activeSection" v-model="activeSection">
            <v-menu-item v-if="isRoleAdmin" name="basic">
              <span class="realty-edit__menu-item">
                Статус и выгрузка
                <v-icon-tick v-if="validations.basicSectionValid" />
              </span>
            </v-menu-item>
            <v-menu-item name="aboutObject">
              <span class="realty-edit__menu-item">
                Об объекте
                <v-icon-tick v-if="validations.aboutObjectSectionValid" />
              </span>
            </v-menu-item>
            <v-menu-item v-if="realtyModel.category" name="description">
              <span class="realty-edit__menu-item">
                {{ isNmh ? 'Название и описание для сайта' : 'Описание' }}
                <v-icon-tick v-if="validations.descriptionSectionValid" />
              </span>
            </v-menu-item>
            <v-menu-item v-if="isNmh && isRoleAdmin" name="seo">
              <span class="realty-edit__menu-item">
                SEO
                <v-icon-tick v-if="validations.seoSectionValid" />
              </span>
            </v-menu-item>

            <v-menu-item name="placement">
              <span class="realty-edit__menu-item">
                Расположение
                <v-icon-tick v-if="validations.placementSectionValid" />
              </span>
            </v-menu-item>
            <v-menu-item name="photos">
              <span class="realty-edit__menu-item">
                Фотографии
                <v-icon-tick v-if="validations.photosSectionValid" />
              </span>
            </v-menu-item>
            <v-menu-item v-if="isRoleAdmin" name="agent">
              <span class="realty-edit__menu-item">
                Ответственный агент
                <v-icon-tick v-if="validations.agentsSectionValid" />
              </span>
            </v-menu-item>
            <v-menu-item v-if="isRoleAdmin" name="links">
              <span class="realty-edit__menu-item">
                Ссылки
                <v-icon-tick v-if="validations.linksSectionValid" />
              </span>
            </v-menu-item>
          </v-menu>
        </div>

        <!-- sections -->
        <div v-if="!stateLoading" class="realty-edit__sections-wrapper">
          <edit-realty-general
            v-show="['general', 'basic', 'aboutObject', 'description', 'seo'].includes(activeSection)"
            :active-section="activeSection"
          />
          <edit-realty-placement v-show="activeSection === 'placement'" />
          <edit-realty-photos v-show="activeSection === 'photos'" />
          <edit-realty-responsible-agent
            v-if="isRoleAdmin"
            v-show="activeSection === 'agent'"
            :profile-mode="isStatusDraft"
          />
          <edit-realty-links v-if="isRoleAdmin" v-show="activeSection === 'links'" />
        </div>

        <div v-if="!mobile || !activeSection" class="realty-edit__actions">
          <template v-if="isRoleAdmin">
            <template v-if="initialNotificationId">
              <v-button class="realty-edit__action realty-edit__action" :disabled="loading" @click="rejectDraft"
                >Отклонить</v-button
              >
              <v-button
                class="realty-edit__action"
                :disabled="!isValidForSubmit || loading"
                primary
                @click="updateDraft(true)"
                >Принять</v-button
              >
            </template>

            <template v-else>
              <v-button v-if="isCreation" class="realty-edit__action" :disabled="loading" @click="createDraft(false)"
                >Сохранить черновик</v-button
              >
              <v-button
                v-else
                class="realty-edit__action"
                :disabled="loading"
                @click="isStatusDraft ? updateDraft(false) : createDraftBasedOnRealty(false)"
                >Сохранить черновик</v-button
              >

              <v-button
                v-if="isStatusDraft"
                class="realty-edit__action"
                :disabled="!isValidForSubmit || loading"
                primary
                @click="updateDraft(true)"
                >Опубликовать</v-button
              >
              <v-button
                v-else
                class="realty-edit__action"
                :disabled="!isValidForSubmit || loading"
                primary
                @click="isCreation ? createDraft(true) : createDraftBasedOnRealty(true)"
                >Опубликовать</v-button
              >
            </template>
          </template>

          <template v-else>
            <template v-if="isStatusDraft">
              <v-button class="realty-edit__action" :disabled="loading" @click="updateDraft(false)"
                >Сохранить черновик</v-button
              >
              <v-button
                class="realty-edit__action"
                :disabled="!isValidForSubmit || loading"
                primary
                @click="isCreation ? createDraft(true) : updateDraft(true)"
                >Отправить на проверку</v-button
              >
            </template>
            <template v-else>
              <v-button
                class="realty-edit__action"
                :disabled="loading"
                @click="isCreation ? createDraft(false) : createDraftBasedOnRealty(false)"
                >Сохранить черновик</v-button
              >
              <v-button
                class="realty-edit__action"
                :disabled="!isValidForSubmit || loading"
                primary
                @click="isCreation ? createDraft(true) : createDraftBasedOnRealty(true)"
                >Отправить на проверку</v-button
              >
            </template>
          </template>
        </div>
      </div>
    </validation-observer>
  </match-media>
</template>

<script>
import VBreadcrumbs from '@/components/common/VBreadcrumbs.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import EditRealtyGeneral from '@/components/ObjectsRealty/Edit/General.vue'
import VButtonGoBack from '@/components/common/VButtonGoBack.vue'
import EditRealtyPlacement from '@/components/ObjectsRealty/Edit/Placement.vue'
import EditRealtyPhotos from '@/components/ObjectsRealty/Edit/Photos.vue'
import EditRealtyResponsibleAgent from '@/components/ObjectsRealty/Edit/ResponsibleAgent.vue'
import EditRealtyLinks from '@/components/ObjectsRealty/Edit/Links.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import { MatchMedia } from 'vue-component-media-queries'
import { mapActions, mapGetters, mapState } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VButton from '@/components/common/VButton.vue'
import VIconTick from '@/components/icons/VTick.vue'
import {
  FULL_REALTY_MODEL,
  MODULE_REALTY,
  BASIC_INFO,
  ABOUT_OBJECT,
  DESCRIPTION_INFO,
  SEO_INFO,
  PLACEMENT_INFO,
  PHOTOS,
  LINKS,
  RESPONSIBLE_AGENT,
  RESET_STATE,
  FETCH_REALTY_BY_ID
} from '@/store/modules/realty/realty.types'
import { PENDING_STATUS } from '@/constants/photoUploadStatuses'
import { advertsService } from '@/services/http'
import { REALTY_DRAFT_STATUS_DRAFT } from '@/constants/statuses/realty'
import { loadingService } from '@/services/loading'
import noticeService from '@/services/notification'
import { APARTMENTS_GROUP, UNIC_FIELDS_MAP } from '@/constants/objectRealtyOptions'

const APARTMENTS = 'apartments'
const HOUSES = 'houses'

export default {
  name: 'ObjectRealtyEdit',
  components: {
    VButton,
    VBreadcrumbs,
    VPageTitle,
    VButtonGoBack,
    MatchMedia,
    VIconTick,
    VTabs,
    VTab,
    EditRealtyGeneral,
    EditRealtyPlacement,
    EditRealtyPhotos,
    EditRealtyResponsibleAgent,
    EditRealtyLinks,
    VMenu,
    VMenuItem
  },
  inject: ['mediaQueries'],
  data() {
    return {
      categoriesOptions: [],
      localLoading: false,
      validations: {
        seoSectionValid: false,
        basicSectionValid: false,
        descriptionSectionValid: false,
        aboutObjectSectionValid: false,
        placementSectionValid: false,
        photosSectionValid: false,
        linksSectionValid: false,
        agentsSectionValid: false
      }
    }
  },
  computed: {
    selectedCategory() {
      const foundedOption = this.categoriesOptions.find(category => category.value === this.realtyModel.category)
      const isApartmentGroup = APARTMENTS_GROUP.includes(foundedOption?.label)

      return isApartmentGroup ? APARTMENTS : HOUSES
    },
    formattedModel() {
      const undefinedFieldsObject = {}
      Object.keys(UNIC_FIELDS_MAP.dealType).forEach(key => {
        if (key !== this.realtyModel.dealType) {
          UNIC_FIELDS_MAP.dealType[key].forEach(field => {
            undefinedFieldsObject[field] = undefined
          })
        }
      })
      Object.keys(UNIC_FIELDS_MAP.category).forEach(key => {
        if (key !== this.selectedCategory) {
          UNIC_FIELDS_MAP.category[key].forEach(field => {
            undefinedFieldsObject[field] = undefined
          })
        }
      })
      return { ...this.realtyModel, ...undefinedFieldsObject }
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`,
      isRoleAgent: `${MODULE_SESSION}/isRoleAgent`,
      realtyModel: `${MODULE_REALTY}/${FULL_REALTY_MODEL}`,
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    ...mapState(MODULE_SESSION, {
      agentId: state => state.model.id
    }),
    ...mapState(MODULE_REALTY, {
      id: state => state.id,
      stateLoading: state => state.loading,
      uploadingPhotosStatus: state => state.uploadingPhotosStatus
    }),
    loading() {
      return this.localLoading || this.stateLoading
    },
    activeSection: {
      get() {
        return this.$route.query.tab || null
      },
      set(val) {
        this.$router.push({ query: { tab: val } })
      }
    },
    generalActiveSection: {
      get() {
        return this.$route.query.general
      },
      set(val) {
        this.$router.push({ query: { ...this.$route.query, general: val } })
      }
    },
    title() {
      return this.isCreation ? 'Новый объект' : 'Редактирование объекта'
    },
    objectId() {
      return this.$route.params.objectId
    },
    status() {
      return this.$route.params.status
    },
    isStatusDraft() {
      return this.status === REALTY_DRAFT_STATUS_DRAFT
    },
    initialNotificationId() {
      return this.$route.params.initialNotificationId
    },
    isCreation() {
      return !this.objectId
    },
    breadcrumbItems() {
      return [
        {
          text: 'Объект недвижимости',
          to: { name: 'objects-realty' }
        },
        {
          text: this.isCreation ? 'Новый' : 'Редактирование',
          disabled: true
        }
      ]
    },
    isGeneralTabFilled() {
      if (this.isRoleAdmin)
        return (
          this.validations.basicSectionValid &&
          this.validations.descriptionSectionValid &&
          (!this.isNmh || this.validations.seoSectionValid) &&
          this.validations.aboutObjectSectionValid
        )
      return this.validations.descriptionSectionValid && this.validations.aboutObjectSectionValid
    },
    isValidForSubmit() {
      if (this.isRoleAdmin)
        return (
          this.isGeneralTabFilled &&
          (this.selectedCategory === APARTMENTS || !this.isNmh || this.validations.placementSectionValid) &&
          this.validations.agentsSectionValid &&
          this.validations.photosSectionValid &&
          this.uploadingPhotosStatus !== PENDING_STATUS
        )
      return (
        this.isGeneralTabFilled &&
        (this.selectedCategory === APARTMENTS || !this.isNmh || this.validations.placementSectionValid)
      )
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  created() {
    if (!this.activeSection && !this.mediaQueries.mobile) {
      this.$router.replace({ query: { tab: 'general' } })
    }
    if (!this.isCreation) this.fetchRealtyById({ id: this.objectId, status: this.status })
  },
  mounted() {
    this.setWatchers()
    this.fetchTypeCategories()
  },

  beforeDestroy() {
    this.resetState()
  },
  methods: {
    fetchTypeCategories() {
      this.localLoading = true
      advertsService
        .fetchCategories()
        .then(res => {
          this.categoriesOptions = res.map(el => {
            return {
              value: el.id,
              label: el.name
            }
          })
        })
        .finally(() => {
          this.localLoading = false
        })
    },
    ...mapActions(MODULE_REALTY, {
      resetState: RESET_STATE,
      fetchRealtyById: FETCH_REALTY_BY_ID
    }),
    setWatchers() {
      ;[
        ['basicSectionValid', BASIC_INFO],
        ['descriptionSectionValid', DESCRIPTION_INFO],
        ['seoSectionValid', SEO_INFO],
        ['aboutObjectSectionValid', ABOUT_OBJECT],
        ['placementSectionValid', PLACEMENT_INFO],
        ['agentsSectionValid', RESPONSIBLE_AGENT],
        ['photosSectionValid', PHOTOS],
        ['linksSectionValid', LINKS]
      ].forEach(([key, vid]) => {
        this.$watch(
          () => {
            return this.$refs.commonObserver.observers.find(obs => obs.vid === vid)?.flags.valid
          },
          isValid => {
            this.validations[key] = isValid
          },
          { immediate: true }
        )
      })
    },
    goBack() {
      if (!this.activeSection) {
        this.$router.push({ name: 'objects-realty' })
      } else if (this.generalActiveSection) {
        this.generalActiveSection = undefined
      } else if (this.activeSection) {
        this.activeSection = undefined
      }
    },
    // todo: сделать map константу для сообщений

    createDraft(isWithNotice) {
      if (this.isRoleAgent) {
        this.realtyModel.agent = { id: this.agentId }
      }
      this.localLoading = true
      return advertsService
        .createDraft(this.formattedModel, isWithNotice, this.isRoleAdmin)
        .then(() => {
          let title
          if (isWithNotice)
            title = this.isRoleAdmin ? 'Объект недвижимости опубликован!' : 'Объект недвижимости отправлен на проверку'
          else title = 'Черновик сохранен!'

          noticeService.success(title).then(() => {
            this.$router.push({ name: 'objects-realty' })
          })
        })
        .catch(({ details }) => {
          let title
          if (isWithNotice) title = this.isRoleAdmin ? 'Не удалось опубликовать' : 'Не удалось отправить на проверку'
          else title = 'Не удалось сохранить черновик'

          noticeService.errorWithFields({
            title,
            listTitle: isWithNotice ? 'Необходимо проверить:' : 'Необходимо заполнить:',
            errorFields: details
          })
        })
        .finally(() => {
          this.localLoading = false
        })
    },

    createDraftBasedOnRealty(isWithNotice) {
      if (this.isRoleAgent) {
        this.realtyModel.agent = { id: this.agentId }
      }
      this.localLoading = true
      let successMessage = ''

      if (isWithNotice) {
        if (this.status === 'archive') {
          successMessage = this.isRoleAdmin ? 'Объект успешно опубликован' : 'Черновик отправлен на проверку!'
        } else {
          successMessage = this.isRoleAdmin ? 'Объект обновлён!' : 'Черновик отправлен на проверку!'
        }
      } else successMessage = 'Черновик сохранен!'
      return advertsService
        .createDraftBasedOnRealty(this.id, this.formattedModel, isWithNotice, this.isRoleAdmin)
        .then(() => {
          noticeService.success(successMessage).then(() => {
            this.$router.push({ name: 'objects-realty' })
          })
        })
        .catch(({ details }) => {
          noticeService.errorWithFields({
            title: isWithNotice ? 'Не удалось отправить на проверку' : 'Не удалось сохранить черновик',
            listTitle: isWithNotice ? 'Необходимо проверить:' : 'Необходимо заполнить:',
            errorFields: details
          })
        })
        .finally(() => {
          this.localLoading = false
        })
    },

    updateDraft(isWithNotice) {
      if (this.isRoleAgent) {
        this.realtyModel.agent = { id: this.agentId }
      }
      this.localLoading = true
      return advertsService
        .updateDraft(this.id, this.formattedModel, isWithNotice, this.isRoleAdmin)
        .then(() => {
          let title
          if (this.initialNotificationId) title = 'Успешно принят!'
          else if (this.isRoleAgent && isWithNotice) title = 'Черновик отправлен на проверку'
          else title = 'Черновик обновлен'

          noticeService.success(title).then(() => {
            this.$router.push({ name: 'objects-realty' })
          })
        })
        .catch(({ details }) => {
          let title
          if (this.initialNotificationId) title = 'Не удалось принять!'
          else if (this.isRoleAgent && isWithNotice) title = 'Не удалось отправить на проверку'
          else title = 'Не удалось сохранить черновик'
          noticeService.errorWithFields({
            title,
            listTitle: isWithNotice ? 'Необходимо проверить:' : 'Необходимо заполнить:',
            errorFields: details
          })
        })
        .finally(() => {
          this.localLoading = false
        })
    },

    rejectDraft() {
      this.localLoading = true
      return advertsService
        .rejectDraft(this.id, this.initialNotificationId)
        .then(() => {
          noticeService.success('Успешно отклонено!').then(() => {
            this.$router.push({ name: 'objects-realty' })
          })
        })
        .catch(() => noticeService.error('Не удалось отклонить!'))
        .finally(() => {
          this.localLoading = false
        })
    }
  }
}
</script>
