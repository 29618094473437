<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_REALTY">
    <v-section title="SEO" class="realty-edit-seo">
      <v-form-row>
        <v-form-field
          v-slot="{ validationErrors }"
          label="SEO ЗАГОЛОВОК"
          class="realty-edit-seo__field realty-edit-seo__field--text"
          rules="required"
        >
          <v-input v-model="model.seoTitle" :is-error="!!validationErrors.length" />
        </v-form-field>
      </v-form-row>

      <v-form-row>
        <v-form-field v-slot="{ validationErrors }" label="SEO ОПИСАНИЕ" rules="required">
          <v-textarea v-model="model.seoDescription" :is-error="!!validationErrors.length" />
        </v-form-field>
      </v-form-row>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VTextarea from '@/components/common/VTextarea.vue'
import { SEO_INFO, MODULE_REALTY } from '@/store/modules/realty/realty.types'
import { mapGetters, mapState } from 'vuex'
import { cloneDeep } from '@/utils/common'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

const SECTION_TYPE = SEO_INFO

export default {
  SECTION_TYPE,
  MODULE_REALTY,
  name: 'ObjectsRealtyEditSeo',
  components: { FieldsetWrapper, VSection, VFormField, VFormRow, VInput, VTextarea },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    ...mapState(MODULE_REALTY, {
      stateModel: state => state[SECTION_TYPE]
    }),
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    })
  },
  created() {
    this.model = cloneDeep(this.stateModel)
  }
}
</script>

<style lang="scss"></style>
