<template>
  <match-media v-slot="{ mobile }">
    <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_REALTY">
      <v-section class="realty-edit-photos" :title="mobile ? 'ФОТОГРАФИИ' : ''">
        <validation-provider ref="provider" rules="required">
          <v-image-uploader-with-preview
            :photos="photos"
            with-drop-zone
            @change="changePhotos"
            @add="addPhoto"
            @remove="removePhoto"
            @set-uploading-status="setUploadingStatus"
          >
            <template #dropzone-text><span v-if="mobile"></span></template>
          </v-image-uploader-with-preview>
        </validation-provider>
      </v-section>
    </fieldset-wrapper>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VImageUploaderWithPreview from '@/components/common/VImageUploaderWithPreview.vue'
import { mapState, mapActions } from 'vuex'
import { PHOTOS, MODULE_REALTY, UPDATE_UPLOADING_PHOTOS_STATUS } from '@/store/modules/realty/realty.types'
import { cloneDeep } from '@/utils/common'

const SECTION_TYPE = PHOTOS

export default {
  name: 'ObjectsRealtyEditPhotos',
  components: { MatchMedia, FieldsetWrapper, VSection, VImageUploaderWithPreview },
  SECTION_TYPE,
  MODULE_REALTY,
  data() {
    return {
      model: {
        photos: {},
        advertMediaOrders: {},
        deletedPhotos: [],
        draftMediaOrders: {},
        draftDeletedPhotos: []
      },
      photos: []
    }
  },
  computed: {
    ...mapState(MODULE_REALTY, {
      stateId: state => state.id,
      stateAdvert: state => state.advert,
      statePhotos: state => state.photos,
      stateMediaDrafts: state => state.mediaDrafts,
      stateModel: state => state[SECTION_TYPE]
    })
  },
  watch: {
    photos: {
      handler() {
        this.changePhotoOrders()
        this.$refs.provider.validate(this.photos)
      }
    },
    statePhotos: {
      immediate: true,
      handler(newPhotos) {
        if (newPhotos) {
          const stateMediaDrafts = cloneDeep(this.stateMediaDrafts) || []
          this.photos = [...stateMediaDrafts, ...cloneDeep(newPhotos)].sort((a, b) => a.myOrder - b.myOrder)
        }
      }
    },
    stateMediaDrafts: {
      immediate: true,
      handler(newPhotos) {
        if (newPhotos) {
          const statePhoto = cloneDeep(this.statePhotos) || []
          this.photos = [...statePhoto, ...cloneDeep(newPhotos)].sort((a, b) => a.myOrder - b.myOrder)
        }
      }
    }
  },
  created() {
    this.model = cloneDeep(this.stateModel)
  },
  methods: {
    ...mapActions(MODULE_REALTY, {
      updateUploadingPhotosStatus: UPDATE_UPLOADING_PHOTOS_STATUS
    }),
    changePhotos(photos) {
      this.photos = photos
    },
    setUploadingStatus(status) {
      this.updateUploadingPhotosStatus(status)
    },
    addPhoto(photo) {
      this.photos.push(photo)
      this.model.photos[photo.id] = this.photos.length - 1
    },
    removePhoto(photo) {
      this.photos = this.photos.filter(p => p.id !== photo.id)

      if (this.isCustomPhoto(photo)) delete this.model.photos[photo.id]
      else if (this.isAdverPhoto(photo)) this.model.deletedPhotos.push(photo.id)
      else if (this.isDraftPhoto(photo)) this.model.draftDeletedPhotos.push(photo.id)
    },
    changePhotoOrders() {
      const { advertMediaOrders, draftMediaOrders, photos } = this.photos.reduce(
        (acc, { id }, index) => {
          if (this.isCustomPhoto({ id })) acc.photos[id] = index
          else if (this.isAdverPhoto({ id })) acc.advertMediaOrders[id] = index
          else if (this.isDraftPhoto({ id })) acc.draftMediaOrders[id] = index

          return acc
        },
        { advertMediaOrders: {}, draftMediaOrders: {}, photos: {} }
      )
      this.model = { ...this.model, photos, advertMediaOrders, draftMediaOrders }
    },

    isCustomPhoto({ id }) {
      return this.model.photos && `${id}` in this.model.photos
    },
    isAdverPhoto({ id }) {
      return this.statePhotos && this.statePhotos.find(photo => photo.id === id)
    },
    isDraftPhoto({ id }) {
      return this.stateMediaDrafts && this.stateMediaDrafts.find(photo => photo.id === id)
    }
  }
}
</script>
