<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_REALTY">
    <v-section title="Об объекте" class="realty-edit-about-object">
      <v-form-row class="realty-edit-about-object__row realty-edit-about-object__owner-info">
        <v-form-field label="ID" class="realty-edit-about-object__owner-info-id">
          <v-input :value="id" disabled />
        </v-form-field>
        <v-form-field label="СОБСТВЕННИК" class="realty-edit-about-object__owner-info-name">
          <v-input :value="owner" disabled />
        </v-form-field>
      </v-form-row>

      <v-form-row class="realty-edit-about-object__row">
        <v-form-field v-slot="{ validationErrors }" label="ТИП СДЕЛКИ" separate-label rules="required">
          <select-options
            v-model="model.dealType"
            :options="$options.DEAL_TYPE_OPTIONS"
            :is-error="!!validationErrors.length"
          />
        </v-form-field>
      </v-form-row>
      <template v-if="model.dealType">
        <v-form-row v-if="isRentDealType" class="realty-edit-about-object__row">
          <v-form-field v-slot="{ validationErrors }" label="СРОК АРЕНДЫ" separate-label rules="required">
            <select-options
              v-model="model.rentDuration"
              :options="$options.RENT_DURATION_TYPE_OPTIONS"
              :is-error="!!validationErrors.length"
            />
          </v-form-field>
        </v-form-row>
        <v-form-row class="realty-edit-about-object__row">
          <v-form-field v-slot="{ validationErrors }" label="КАТЕГОРИЯ НЕДВИЖИМОСТИ" separate-label>
            <select-options
              v-model="model.realtyPurpose"
              :options="$options.REALTY_PURPOSE_OPTIONS"
              :is-error="!!validationErrors.length"
            />
          </v-form-field>
        </v-form-row>
        <v-form-row v-if="model.realtyPurpose" class="realty-edit-about-object__row">
          <v-form-field v-slot="{ validationErrors }" label="ТИП НЕДВИЖИМОСТИ" separate-label rules="required">
            <select-options
              v-model="model.category"
              :loading="categoriesLoading"
              :options="realtyCategories"
              :is-error="!!validationErrors.length"
            />
          </v-form-field>
        </v-form-row>
        <template v-if="model.category">
          <v-form-row class="realty-edit-about-object__row realty-edit-about-object__realty-info">
            <div v-if="isHousesGroup" class="realty-edit-about-object__realty-cadastral">
              <v-form-field
                v-slot="{ validationErrors }"
                label="КАДАСТРОВЫЙ НОМЕР ДОМА"
                class="realty-edit-about-object__field"
              >
                <v-input
                  v-model="model.houseCadastralNumber"
                  :mask="$options.CADASTRAL_NUMBER_MASK"
                  :is-error="!!validationErrors.length"
                />
              </v-form-field>
            </div>

            <div v-if="isHousesGroup || isLandPlotsGroup" class="realty-edit-about-object__realty-cadastral">
              <v-form-field
                v-slot="{ validationErrors }"
                label="КАДАСТРОВЫЙ НОМЕР УЧАСТКА"
                class="realty-edit-about-object__field"
              >
                <v-input
                  v-model="model.landCadastralNumber"
                  :mask="$options.CADASTRAL_NUMBER_MASK"
                  :is-error="!!validationErrors.length"
                />
              </v-form-field>
            </div>

            <div v-if="isApartmentsGroup" class="realty-edit-about-object__realty-cadastral">
              <v-form-field
                v-slot="{ validationErrors }"
                label="КАДАСТРОВЫЙ НОМЕР КВАРТИРЫ"
                class="realty-edit-about-object__field"
              >
                <v-input
                  v-model="model.apartmentCadastralNumber"
                  :mask="$options.CADASTRAL_NUMBER_MASK"
                  :is-error="!!validationErrors.length"
                />
              </v-form-field>
            </div>
          </v-form-row>
          <v-form-row class="realty-edit-about-object__row realty-edit-about-object__realty-info">
            <v-form-field
              v-slot="{ validationErrors }"
              label="СТОИМОСТЬ"
              class="realty-edit-about-object__realty-info-price"
              rules="required"
            >
              <v-input-money v-model="model.price" :is-error="!!validationErrors.length"
                ><template #append
                  ><span class="realty-edit-about-object__input-append">{{ currencyIcon }}</span></template
                ></v-input-money
              >
            </v-form-field>
            <v-form-field v-slot="{ validationErrors }" label="ВАЛЮТА" separate-label rules="required">
              <select-options
                v-model="model.auxiliaryCurrency"
                :options="$options.CURRENCY_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isRentDealType" class="realty-edit-about-object__row realty-edit-about-object__realty-info">
            <v-form-field
              v-slot="{ validationErrors }"
              class="realty-edit-about-object__realty-info-communal"
              label="КОММУНАЛЬНЫЕ ПЛАТЕЖИ"
              separate-label
            >
              <select-options
                v-model="model.communalPaymentsIncluded"
                :options="$options.COMMUNAL_PAYMENTS_TYPE_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
            <v-form-field v-slot="{ validationErrors }" label="СЧЕТЧИКИ" separate-label>
              <select-options
                v-model="model.countersIncluded"
                :options="$options.COUNTERS_TYPE_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isRentDealType" class="realty-edit-about-object__row realty-edit-about-object__realty-info">
            <v-form-field
              v-slot="{ validationErrors }"
              label="ЗАЛОГ СОБСТВЕННИКУ"
              class="realty-edit-about-object__realty-info-deposit"
              rules="required"
            >
              <v-input-money v-model="model.deposit" :is-error="!!validationErrors.length"
                ><template #append
                  ><span class="realty-edit-about-object__input-append">{{ currencyIcon }}</span></template
                ></v-input-money
              >
            </v-form-field>
            <v-form-field label="ПРЕДОПЛАТА" separate-label class="realty-edit-about-object__realty-info-prepayment">
              <v-select
                v-model="model.prepaymentMonths"
                :clearable="false"
                :options="$options.PREPAYMENT_TYPE_OPTIONS"
                label="label"
                :reduce="option => option.value"
              ></v-select>
            </v-form-field>
          </v-form-row>
          <v-form-row
            v-if="isSaleDealType"
            class="realty-edit-about-object__row realty-edit-about-object__row--mortgage"
          >
            <v-form-field v-slot="{ validationErrors }" label="ВОЗМОЖНА ИПОТЕКА" separate-label>
              <select-options
                v-model="model.mortgage"
                :options="$options.MORTGAGE_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
            <v-form-field
              v-if="!isNmh && isApartmentsGroup"
              v-slot="{ validationErrors }"
              label="ТИП СДЕЛКИ"
              separate-label
            >
              <select-options
                v-model="model.feedDealType"
                :options="$options.DEAL_TYPE_MORTGAGE_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row class="realty-edit-about-object__row realty-edit-about-object__realty-info">
            <v-form-field
              v-if="!isLandPlotsGroup"
              v-slot="{ validationErrors }"
              :label="isHousesGroup ? 'ПЛОЩАДЬ ОБЪЕКТА' : 'ОБЩАЯ ПЛОЩАДЬ'"
              class="realty-edit-about-object__realty-info-area realty-edit-about-object__realty-info-area--realty"
              rules="required"
            >
              <v-input v-model="model.area" :is-error="!!validationErrors.length" type="number">
                <template #append><span class="realty-edit-about-object__input-append">кв.м</span></template>
              </v-input>
            </v-form-field>
            <v-form-field
              v-if="isHousesGroup || isLandPlotsGroup"
              v-slot="{ validationErrors }"
              label="ПЛОЩАДЬ УЧАСТКА"
              class="realty-edit-about-object__realty-info-plottage"
              rules="required"
            >
              <v-input v-model="model.plottage" :is-error="!!validationErrors.length" type="number"
                ><template #append><span class="realty-edit-about-object__input-append">сот.</span></template></v-input
              >
            </v-form-field>
            <v-form-field
              v-if="isHousesGroup"
              v-slot="{ validationErrors }"
              label="ГОД ПОСТРОЙКИ"
              class="realty-edit-about-object__realty-info-build-year"
            >
              <v-input v-model="model.buildYear" :is-error="!!validationErrors.length" type="number"
                ><template #append><span class="realty-edit-about-object__input-append">год</span></template></v-input
              >
            </v-form-field>
            <template v-if="isApartmentsGroup">
              <v-form-field
                v-slot="{ validationErrors }"
                label="ЖИЛАЯ ПЛОЩАДЬ"
                class="realty-edit-about-object__realty-info-area"
                rules="required"
              >
                <v-input v-model="model.livingArea" :is-error="!!validationErrors.length" type="number">
                  <template #append><span class="realty-edit-about-object__input-append">кв.м</span></template>
                </v-input>
              </v-form-field>
              <v-form-field
                v-slot="{ validationErrors }"
                label="ПЛОЩАДЬ КУХНИ"
                class="realty-edit-about-object__realty-info-area"
              >
                <v-input v-model="model.kitchenArea" :is-error="!!validationErrors.length" type="number">
                  <template #append><span class="realty-edit-about-object__input-append">кв.м</span></template>
                </v-input>
              </v-form-field>
            </template>
          </v-form-row>
          <v-form-row
            v-if="!isLandPlotsGroup"
            class="realty-edit-about-object__row
       realty-edit-about-object__realty-info
       realty-edit-about-object__realty-info--count"
          >
            <v-form-field
              v-slot="{ validationErrors }"
              label="КОЛ-ВО СПАЛЕН"
              class="realty-edit-about-object__realty-info-item"
              :rules="isHousesGroup ? 'required' : ''"
            >
              <v-input v-model="model.bedroomsCount" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
            <v-form-field
              v-if="isApartmentsGroup"
              v-slot="{ validationErrors }"
              label="КОЛ-ВО КОМНАТ"
              class="realty-edit-about-object__realty-info-rooms-count"
              rules="required"
              separate-label
            >
              <v-select
                v-model="model.roomCount"
                :is-error="!!validationErrors.length"
                :clearable="false"
                :options="$options.ROOM_COUNT_OPTIONS"
                label="label"
                :reduce="option => option.value"
              ></v-select>
            </v-form-field>
            <template v-if="isHousesGroup">
              <v-form-field
                v-slot="{ validationErrors }"
                label="КОЛ-ВО САНУЗЛОВ"
                class="realty-edit-about-object__realty-info-item"
                rules="required"
              >
                <v-input v-model="model.wcCount" :is-error="!!validationErrors.length" type="number" />
              </v-form-field>
              <v-form-field
                v-slot="{ validationErrors }"
                label="КОЛ-ВО ЭТАЖЕЙ"
                class="realty-edit-about-object__realty-info-item"
                rules="required"
              >
                <v-input v-model="model.floors" :is-error="!!validationErrors.length" type="number" />
              </v-form-field>
              <v-form-field
                v-slot="{ validationErrors }"
                label="КОЛ-ВО УРОВНЕЙ"
                class="realty-edit-about-object__realty-info-item"
                rules="required"
              >
                <v-input v-model="model.layers" :is-error="!!validationErrors.length" type="number" />
              </v-form-field>
            </template>
          </v-form-row>
          <v-form-row
            v-if="isApartmentsGroup"
            class="realty-edit-about-object__row
       realty-edit-about-object__realty-info
       realty-edit-about-object__realty-info--count"
          >
            <v-form-field
              v-slot="{ validationErrors }"
              label="ЛОДЖИИ"
              class="realty-edit-about-object__realty-info-item"
            >
              <v-input v-model="model.loggiaCount" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
            <v-form-field
              v-slot="{ validationErrors }"
              label="БАЛКОНЫ"
              class="realty-edit-about-object__realty-info-item"
            >
              <v-input v-model="model.balconiesCount" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
          </v-form-row>
          <v-form-row
            v-if="isApartmentsGroup"
            class="realty-edit-about-object__row
       realty-edit-about-object__realty-info
       realty-edit-about-object__realty-info--count"
          >
            <v-form-field
              v-slot="{ validationErrors }"
              label="РАЗДЕЛЬНЫЕ С/У"
              class="realty-edit-about-object__realty-info-item"
            >
              <v-input v-model="model.separateWcCount" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
            <v-form-field
              v-slot="{ validationErrors }"
              label="СОВМЕЩЕННЫЕ С/У"
              class="realty-edit-about-object__realty-info-item"
            >
              <v-input v-model="model.combinedWcCount" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isApartmentsGroup" class="realty-edit-about-object__row">
            <v-form-field v-slot="{ validationErrors }" label="ТИП РЕМОНТА" separate-label>
              <select-options
                v-model="model.renovationType"
                :options="$options.RENOVATION_TYPE_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row
            v-if="isApartmentsGroup"
            class="realty-edit-about-object__row
       realty-edit-about-object__realty-info
       realty-edit-about-object__realty-info--count"
          >
            <v-form-field
              v-slot="{ validationErrors }"
              label="ЭТАЖ"
              class="realty-edit-about-object__realty-info-item"
              rules="required"
            >
              <v-input v-model="model.floor" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
            <v-form-field
              v-slot="{ validationErrors }"
              label="КОЛ-ВО ЭТАЖЕЙ В ДОМЕ"
              class="realty-edit-about-object__realty-info-item"
              rules="required"
            >
              <v-input v-model="model.floors" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
          </v-form-row>
          <v-form-row
            v-if="isApartmentsGroup"
            class="realty-edit-about-object__row
       realty-edit-about-object__realty-info"
          >
            <v-form-field
              v-slot="{ validationErrors }"
              label="НАЗВАНИЕ ЖК"
              class="realty-edit-about-object__realty-info-housing-complex"
            >
              <v-input v-model="model.housingComplexName" :is-error="!!validationErrors.length" />
            </v-form-field>
            <v-form-field
              v-slot="{ validationErrors }"
              label="ГОД ПОСТРОЙКИ"
              class="realty-edit-about-object__realty-info-housing-complex"
            >
              <v-input v-model="model.buildYear" :is-error="!!validationErrors.length" type="number"
                ><template #append><span class="realty-edit-about-object__input-append">год</span></template></v-input
              >
            </v-form-field>
            <v-form-field
              v-slot="{ validationErrors }"
              label="ID ЖК ДЛЯ ЦИАН"
              class="realty-edit-about-object__realty-info-housing-complex"
            >
              <v-input v-model="model.housingComplexId" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="!isLandPlotsGroup" class="realty-edit-about-object__row">
            <v-form-field
              v-slot="{ validationErrors }"
              label="ТИП ДОМА"
              separate-label
              :rules="isHousesGroup ? 'required' : ''"
            >
              <select-options
                v-model="model.houseType"
                :options="houseTypeOptions"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isApartmentsGroup" class="realty-edit-about-object__row">
            <v-form-field v-slot="{ validationErrors }" label="ПАРКОВКА" separate-label>
              <select-options
                v-model="model.parkingLot"
                :options="$options.PARKING_TYPE_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row
            v-if="isApartmentsGroup"
            class="realty-edit-about-object__row
       realty-edit-about-object__realty-info"
          >
            <v-form-field
              v-slot="{ validationErrors }"
              label="ПАССАЖИРСКИЕ ЛИФТЫ"
              class="realty-edit-about-object__realty-info-elevators"
            >
              <v-input v-model="model.elevatorCount" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
            <v-form-field
              v-slot="{ validationErrors }"
              label="ГРУЗОВЫЕ ЛИФТЫ"
              class="realty-edit-about-object__realty-info-elevators"
            >
              <v-input v-model="model.serviceLiftCount" :is-error="!!validationErrors.length" type="number" />
            </v-form-field>
          </v-form-row>
          <v-form-row
            v-if="isApartmentsGroup"
            class="realty-edit-about-object__row
       realty-edit-about-object__realty-info"
          >
            <v-form-field label="ОКНА" separate-label>
              <div class="realty-edit-about-object__checkboxes">
                <v-checkbox v-model="model.windowsCourtyard" class="realty-edit-about-object__checkbox"
                  >Во двор</v-checkbox
                >
                <v-checkbox v-model="model.windowsOutdoor" class="realty-edit-about-object__checkbox"
                  >На улицу</v-checkbox
                >
              </div>
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isHousesGroup" class="realty-edit-about-object__row">
            <v-form-field v-slot="{ validationErrors }" label="ТИП ДОМА (ДЛЯ АВИТО)" separate-label rules="required">
              <select-options
                v-model="model.avitoWallsType"
                :options="$options.AVITO_WALLS_TYPE_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isHousesGroup" class="realty-edit-about-object__row">
            <v-form-field
              v-slot="{ validationErrors }"
              label="ТИП ОТДЕЛКИ"
              separate-label
              class="realty-edit-about-object__field"
              rules="required"
            >
              <select-options
                v-model="model.facingType"
                :options="$options.FACING_TYPE_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isHousesGroup || isLandPlotsGroup" class="realty-edit-about-object__row">
            <v-form-field
              v-slot="{ validationErrors }"
              label="ТИП УЧАСТКА"
              separate-label
              class="realty-edit-about-object__field"
              rules="required"
            >
              <select-options
                v-model="model.plotType"
                :options="$options.PLOT_TYPE_OPTIONS"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isHousesGroup || isLandPlotsGroup" class="realty-edit-about-object__row">
            <v-form-field label="КОММУНИКАЦИИ" separate-label>
              <div class="realty-edit-about-object__checkboxes">
                <v-checkbox v-model="model.isGas" class="realty-edit-about-object__checkbox">Газ</v-checkbox>
                <v-checkbox v-model="model.isElectricity" class="realty-edit-about-object__checkbox"
                  >Электричество</v-checkbox
                >
                <v-checkbox v-model="model.isSewerage" class="realty-edit-about-object__checkbox"
                  >Канализация</v-checkbox
                >
                <v-checkbox v-model="model.isWater" class="realty-edit-about-object__checkbox"
                  >Водоснабжение</v-checkbox
                >
              </div>
            </v-form-field>
          </v-form-row>
          <v-form-row class="realty-edit-about-object__row">
            <v-form-field
              v-slot="{ validationErrors }"
              :label="isApartmentsGroup ? 'В КВАРТИРЕ' : 'ТЭГИ'"
              separate-label
              class="realty-edit-about-object__field realty-edit-about-object__field--select"
              rules="required"
            >
              <v-select
                v-model="model.tags"
                :loading="tagsLoading"
                multiple
                :options="tagsOptions"
                label="name"
                :close-on-select="false"
                :reduce="tag => tag.id"
                :is-error="!!validationErrors.length"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row v-if="isRoleAdmin" class="realty-edit-about-object__row">
            <v-form-field
              label="ССЫЛКА YOUTUBE"
              class="realty-edit-about-object__field realty-edit-about-object__field--youtube"
            >
              <v-input v-model="model.youtubeUrl" />
            </v-form-field>
          </v-form-row>
        </template>
      </template>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VSelect from '@/components/common/VSelect.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VCheckbox from '@/components/common/VCheckbox.vue'
import selectOptions from '@/components/ObjectsRealty/Edit/SelectOptions.vue'
import VInput from '@/components/common/VInput.vue'
import VInputMoney from '@/components/common/VInputMoney.vue'
import {
  CURRENCY_OPTIONS,
  HOUSE_TYPE_OPTIONS,
  AVITO_WALLS_TYPE_OPTIONS,
  FACING_TYPE_OPTIONS,
  PLOT_TYPE_OPTIONS,
  LAND_PLOTS,
  DEAL_TYPE_OPTIONS,
  MORTGAGE_OPTIONS,
  RENOVATION_TYPE_OPTIONS,
  PARKING_TYPE_OPTIONS,
  RENT_DURATION_TYPE_OPTIONS,
  COUNTERS_TYPE_OPTIONS,
  COMMUNAL_PAYMENTS_TYPE_OPTIONS,
  PREPAYMENT_TYPE_OPTIONS,
  REALTY_PURPOSE_OPTIONS,
  DEAL_TYPE_RENT,
  DEAL_TYPE_SALE,
  FETCH_TAGS_ADVERT_TYPE_VALUES_MAP,
  APARTMENTS_GROUP,
  HOUSES_GROUP,
  LAND_PLOTS_GROUP,
  ROOM_COUNT_OPTIONS,
  DEAL_TYPE_MORTGAGE_OPTIONS
} from '@/constants/objectRealtyOptions'
import { mapGetters, mapState } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { advertsService } from '@/services/http'
import { ABOUT_OBJECT, MODULE_REALTY } from '@/store/modules/realty/realty.types'
import { cloneDeep } from '@/utils/common'
import { CADASTRAL_NUMBER_MASK } from '@/constants/textMask'

const SECTION_TYPE = ABOUT_OBJECT

export default {
  DEAL_TYPE_MORTGAGE_OPTIONS,
  REALTY_PURPOSE_OPTIONS,
  PREPAYMENT_TYPE_OPTIONS,
  ROOM_COUNT_OPTIONS,
  COMMUNAL_PAYMENTS_TYPE_OPTIONS,
  COUNTERS_TYPE_OPTIONS,
  RENT_DURATION_TYPE_OPTIONS,
  PARKING_TYPE_OPTIONS,
  RENOVATION_TYPE_OPTIONS,
  MORTGAGE_OPTIONS,
  CURRENCY_OPTIONS,
  CADASTRAL_NUMBER_MASK,
  DEAL_TYPE_OPTIONS,
  AVITO_WALLS_TYPE_OPTIONS,
  FACING_TYPE_OPTIONS,
  PLOT_TYPE_OPTIONS,
  SECTION_TYPE,
  MODULE_REALTY,
  name: 'ObjectsRealtyEditAbout',
  components: {
    FieldsetWrapper,
    VSection,
    VFormField,
    VFormRow,
    VCheckbox,
    VInput,
    VSelect,
    selectOptions,
    VInputMoney
  },
  data() {
    return {
      model: {},
      tagsLoading: false,
      categoriesLoading: false,
      tagsOptions: [],
      categoriesOptions: []
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`,
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    ...mapState(MODULE_REALTY, {
      id: state => state.id,
      owner: state => state.owner?.name,
      stateModel: state => state[SECTION_TYPE]
    }),
    houseTypeOptions() {
      return HOUSE_TYPE_OPTIONS.filter(type => {
        return this.isHousesGroup ? type.forHouse : type.forFlat
      })
    },
    isRentDealType() {
      return this.model.dealType === DEAL_TYPE_RENT
    },
    isSaleDealType() {
      return this.model.dealType === DEAL_TYPE_SALE
    },
    realtyCategories() {
      return this.isRentDealType
        ? this.categoriesOptions.filter(category => category.label !== LAND_PLOTS)
        : this.categoriesOptions
    },
    currencyIcon() {
      if (this.model.auxiliaryCurrency === 'EUR') return '€'
      if (this.model.auxiliaryCurrency === 'USD') return '$'
      return '₽'
    },
    isApartmentsGroup() {
      return this.checkCategoryIsApartmentsGroup()
    },
    isHousesGroup() {
      return this.checkCategoryIsHousesGroup()
    },
    isLandPlotsGroup() {
      return this.checkCategoryIsLandPlotsGroup()
    }
  },
  watch: {
    'model.dealType': {
      handler(dealType, oldDealType) {
        if (dealType === DEAL_TYPE_RENT && this.isLandPlotsGroup) {
          this.model.category = null
        }
        if (oldDealType) {
          this.model.tags = []
        }
        if (this.model.category) {
          this.fetchTagsOptions(
            FETCH_TAGS_ADVERT_TYPE_VALUES_MAP[this.isApartmentsGroup ? 'apartments' : 'housesAndPlots'],
            this.model.dealType
          )
        }
      }
    },
    'model.category': {
      handler(category, oldCategory) {
        if (category) {
          if (
            !(
              ((this.checkCategoryIsHousesGroup(oldCategory) || this.checkCategoryIsLandPlotsGroup(oldCategory)) &&
                (this.checkCategoryIsHousesGroup(category) || this.checkCategoryIsLandPlotsGroup(category))) ||
              (this.checkCategoryIsApartmentsGroup(oldCategory) && this.checkCategoryIsApartmentsGroup(category))
            )
          ) {
            if (oldCategory) {
              this.model.tags = []
            }
            this.fetchTagsOptions(
              FETCH_TAGS_ADVERT_TYPE_VALUES_MAP[this.isApartmentsGroup ? 'apartments' : 'housesAndPlots'],
              this.model.dealType
            )
          }
        }
      }
    }
  },
  created() {
    this.model = {
      ...cloneDeep(this.stateModel),
      price: this.stateModel.auxiliaryPrice || this.stateModel.price
    }
    this.fetchTypeCategories().then(() => {
      this.fetchTagsOptions(
        FETCH_TAGS_ADVERT_TYPE_VALUES_MAP[this.isApartmentsGroup ? 'apartments' : 'housesAndPlots'],
        this.model.dealType
      )
    })
  },
  methods: {
    checkCategoryIsApartmentsGroup(categoryObject = this.model.category) {
      return this.checkByTypes(APARTMENTS_GROUP, categoryObject)
    },
    checkCategoryIsHousesGroup(categoryObject = this.model.category) {
      return this.checkByTypes(HOUSES_GROUP, categoryObject)
    },
    checkCategoryIsLandPlotsGroup(categoryObject = this.model.category) {
      return this.checkByTypes(LAND_PLOTS_GROUP, categoryObject)
    },
    fetchTagsOptions(advertType, dealType) {
      this.tagsLoading = true
      advertsService
        .selectTags({ advertType, dealType })
        .then(res => {
          this.tagsOptions = res
        })
        .finally(() => {
          this.tagsLoading = false
        })
    },
    fetchTypeCategories() {
      this.categoriesLoading = true
      return advertsService
        .fetchCategories()
        .then(res => {
          this.categoriesOptions = res.map(el => {
            return {
              value: el.id,
              label: el.name
            }
          })
        })
        .finally(() => {
          this.categoriesLoading = false
        })
    },
    typeRealtyObject(categoryObject) {
      return this.categoriesOptions.find(category => category.value === categoryObject)?.label
    },
    checkByTypes(types, categoryObject) {
      return types.includes(this.typeRealtyObject(categoryObject))
    }
  }
}
</script>
