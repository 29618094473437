<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_REALTY">
    <v-section title="Расположение" class="realty-edit-placement">
      <v-form-row>
        <v-map v-model="coords" :address.sync="model.address" />
      </v-form-row>
      <template v-if="!isApartmentsGroup">
        <template v-if="isNmh">
          <v-form-row class="realty-edit-placement__row">
            <v-form-field
              label="НАСЕЛЕННЫЙ ПУНКТ"
              separate-label
              class="realty-edit-placement__field realty-edit-placement__field--half-size"
            >
              <v-select
                v-model="model.settlement"
                :loading="settleLoading"
                :options="settleOptions"
                :reduce="settle => settle.id"
                label="name"
              />
            </v-form-field>
            <v-form-field
              label="КОТТЕДЖНЫЙ ПОСЕЛОК"
              separate-label
              class="realty-edit-placement__field realty-edit-placement__field--half-size"
            >
              <v-select
                v-model="model.village"
                :loading="villageLoading"
                :options="villageOptions"
                :reduce="village => village.id"
                label="name"
              />
            </v-form-field>
          </v-form-row>

          <v-form-row class="realty-edit-placement__row">
            <v-form-field
              v-slot="{ validationErrors }"
              label="ДОЛГОТА"
              separate-label
              class="realty-edit-placement__field realty-edit-placement__field--half-size"
              rules="required"
            >
              <v-input v-model="model.longitude" :is-error="!!validationErrors.length" />
            </v-form-field>
            <v-form-field
              v-slot="{ validationErrors }"
              label="ШИРОТА"
              separate-label
              class="realty-edit-placement__field realty-edit-placement__field--half-size"
              rules="required"
            >
              <v-input v-model="model.latitude" :is-error="!!validationErrors.length" />
            </v-form-field>
          </v-form-row>
        </template>
        <v-form-row v-else class="realty-edit-placement__row">
          <v-form-field
            label="НАЗВАНИЕ КОТТЕДЖНОГО ПОСЁЛКА"
            separate-label
            class="realty-edit-placement__field realty-edit-placement__field--half-size"
          >
            <v-input v-model="model.villageName" />
          </v-form-field>
          <v-form-field
            label="ID КОТТЕДЖНОГО ПОСЁЛКА ДЛЯ ЦИАН"
            separate-label
            class="realty-edit-placement__field realty-edit-placement__field--half-size"
          >
            <v-input v-model="model.villageCianId" />
          </v-form-field>
        </v-form-row>
      </template>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VSection from '@/components/common/VSection.vue'
import VMap from '@/components/common/VMap.vue'
import VInput from '@/components/common/VInput.vue'
import VSelect from '@/components/common/VSelect.vue'
import { advertsService, geoService, villagesService } from '@/services/http'
import { mapGetters, mapState } from 'vuex'
import { ABOUT_OBJECT, MODULE_REALTY, PLACEMENT_INFO } from '@/store/modules/realty/realty.types'
import cloneDeep from 'lodash.clonedeep'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { APARTMENTS_GROUP } from '@/constants/objectRealtyOptions'

const SECTION_TYPE = PLACEMENT_INFO

export default {
  SECTION_TYPE,
  MODULE_REALTY,
  name: 'ObjectsRealtyEditPlacement',
  components: { FieldsetWrapper, VFormField, VFormRow, VSection, VMap, VInput, VSelect },
  data() {
    return {
      model: {},

      settleLoading: false,
      villageLoading: false,
      settleOptions: [],
      categoriesOptions: [],
      villageOptions: []
    }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    ...mapState(MODULE_REALTY, {
      stateModel: state => state[SECTION_TYPE],
      objectCategoryId: state => state[ABOUT_OBJECT].category
    }),
    isApartmentsGroup() {
      return APARTMENTS_GROUP.includes(
        this.categoriesOptions.find(category => category.value === this.objectCategoryId)?.label
      )
    },
    coords: {
      get() {
        if (this.model.latitude && this.model.longitude) {
          return [this.model.latitude, this.model.longitude]
        }
        return null
      },

      set(newCoords) {
        ;[this.model.latitude, this.model.longitude] = newCoords
      }
    }
  },
  created() {
    this.model = cloneDeep(this.stateModel)

    this.fetchSettleOptions()
    this.fetchVillageOptions()
    this.fetchTypeCategories()
  },
  methods: {
    fetchTypeCategories() {
      advertsService.fetchCategories().then(res => {
        this.categoriesOptions = res.map(el => {
          return {
            value: el.id,
            label: el.name
          }
        })
      })
    },
    fetchSettleOptions() {
      this.settleLoading = true
      geoService
        .getSettlements()
        .then(result => {
          this.settleOptions = result
        })
        .finally(() => {
          this.settleLoading = false
        })
    },
    fetchVillageOptions() {
      this.villageLoading = true
      villagesService
        .select()
        .then(result => {
          this.villageOptions = result
        })
        .finally(() => {
          this.villageLoading = false
        })
    }
  }
}
</script>
